import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if(this.props.data){
      var educationList = this.props.data.education.map(function(education)
      {
        return <div key={education.school}>
          <div className="uni">
            <img src={education.image} alt="Logo"></img>
            <h3>{education.school}</h3>
          </div>
        <p className="info">{education.degree} <em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      })
      var workexp = this.props.data.work.map(function(work){
        return <div key={work.company}>
          <div className="uni">
            <img src={work.image} alt="Logo"></img>
            <h3>{work.company}</h3>
          </div>
            <p className="info">{work.title}  <em className="date">{work.years}</em></p>
            <p>{work.description}</p>
        </div>
      })
      var skillsP = this.props.data.skills.map(function(skills){
        var className = 'bar-expand '+skills.name.toLowerCase();
        return <li 
                key={skills.name}> 
                <span style={{width:skills.level}}className={className}></span>
                <div className="skillsimage">
                  <em>{skills.name}</em>
                </div> 
                
                </li>
      })
      var skillsD = this.props.data.skills1.map(function(skills1){
        var className = 'bar-expandD '+skills1.name.toLowerCase();
        return <li 
                key={skills1.name}> 
                <span style={{width:skills1.level}}className={className}></span>
                <div className="skillsimage">
                  <em>{skills1.name}</em>
                </div> 
                
                </li>
      })
      var skillsT = this.props.data.skills2.map(function(skills2){
        var className = 'bar-expandT ' +skills2.name.toLowerCase();
        return <li 
                key={skills2.name}> 
                <span style={{width:skills2.level}}className={className}></span>
                <div className="skillsimage">
                  <em>{skills2.name}</em>
                </div> 
                
                </li>
      })
    }

    return (
      <section id="resume">
        <div className="resumelist">

        <div className="row work">

          <div className="three columns header-col">
              <h1><span>Work</span></h1>
          </div>

          <div className="nine columns main-col">
            {workexp}
          </div>
       </div>
   


        <div className="row education">
          <div className="three columns header-col">
              <h1><span>Education</span></h1>
          </div>
          

          <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  {educationList}
                </div>
              </div>
        </div>


    </div>

          
        </div>


      <div className="row skill">

            <h1><span>Skills</span></h1>
          <div className="skilllist" >
            <div className="nine columns main-col">

                <p>Programming
                </p>

                <div className="bars">
                  <ul className="skills">
                    {skillsP}
                  </ul>
                </div>
            </div>

            <div className="nine columns main-col">
                <p>Design
                </p>
                <div className="bars">
                  <ul className="skills">
                    {skillsD}
                  </ul>
                </div>
            </div>

           <div className="nine columns main-col">
                <p>Tech
                </p>
                <div className="bars">
                  <ul className="skills">
                    {skillsT}
                  </ul>
                </div>
            </div>

          </div>
      </div>
   </section>
    );
  }
}

export default Resume;
